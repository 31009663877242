import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from 'axios-extensions';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import Head from 'next/head';
import React from 'react';

import { LoginPage } from '@/components/architecture/LoginGate/LoginPage';
import {
  EMAIL_QUERY_PARAM,
  LoginCodes,
  MESSAGE_QUERY_PARAM,
} from '@/components/architecture/LoginGate/LoginPage.constants';
import { getValidatedStatusMessage } from '@/components/architecture/LoginGate/LoginPage.utils';
import { FeedbackProvider } from '@/components/notifications/Feedback/FeedbackProvider';
import { isValidTenantSubdomain } from '@/modules/tenant/tenant.utils';
import { getThemeFromTenantBranding } from '@/styles/themes/themes.utils';
import { COLORS } from '@/styles/tokens/colors';
import { getKnownValidDomain } from '@/utils/environmentUtils';

import { ViewportMeta } from '../pageUtils/meta';

interface TenantBrandingAPIResponse {
  tenant_branding: {
    id: string;
    primary_color: string;
    secondary_color: string;
    data_visualization_primary_color: string;
    data_visualization_secondary_color: string;
    data_visualization_tertiary_color: string;
    data_visualization_negative_color: string;
    buttons_link_color: string;
    logo_url: string;
    display_name?: string;
  };
  is_invalid_tenant: boolean;
}

interface LoginProps {
  branding: TenantBrandingAPIResponse['tenant_branding'];
  invalidTenantSubdomain: string | null;
  tenantURL: string;
  statusMessage: LoginCodes | null;
  redirectPath: string | null;
  userEmail: string | null;
}

function getPotentialSubdomain(host: string | undefined): string | null {
  if (!host) return null;
  return host.split('.')[0] ?? null;
}

function getSafeSubdomainFromHost(host: string | undefined): string | null {
  const potentialSubdomain = getPotentialSubdomain(host);
  if (!potentialSubdomain || !isValidTenantSubdomain(potentialSubdomain)) {
    return null;
  }

  return potentialSubdomain;
}

const defaultTenantBrandingResponse: TenantBrandingAPIResponse['tenant_branding'] =
  {
    display_name: 'Luminary',
    primary_color: COLORS.NAVY[900],
    secondary_color: COLORS.NAVY[500],
    buttons_link_color: COLORS.NAVY[900],

    // none of these are used
    id: 'UNKNOWN',
    data_visualization_primary_color: '#000000',
    data_visualization_secondary_color: '#000000',
    data_visualization_tertiary_color: '#000000',
    data_visualization_negative_color: '#000000',
    logo_url: '',
  };

const http = axios.create({
  baseURL: '/',
  // defaults to `new LRUCache({ maxAge: FIVE_MINUTES, max: 100 })`
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.getAdapter(axios.defaults.adapter))
  ),
});

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<LoginProps>> {
  // this is a safe subdomain in that it matches our regex for a valid subdomain
  const safeSubdomain = getSafeSubdomainFromHost(context.req.headers.host);

  const domain = getKnownValidDomain();
  if (!safeSubdomain) {
    return {
      props: {
        redirectPath: null,
        userEmail: null,
        branding: defaultTenantBrandingResponse,
        invalidTenantSubdomain: getPotentialSubdomain(context.req.headers.host),
        tenantURL: context.req.headers.host ?? '',
        statusMessage: getValidatedStatusMessage(LoginCodes.UNKNOWN_ERROR), // this should not ever be a string[]
      },
    };
  }
  // we're calling this special public HTTP endpoint rather than our typical graphql call because
  // we don't currently have a good/clean way of handling unauthenticated graphql calls.
  const {
    data: { tenant_branding, is_invalid_tenant: tenantDoesNotExist },
  } = await http<TenantBrandingAPIResponse>(
    `/api/public/branding?tenant=${safeSubdomain}`,
    {
      baseURL: `http://${safeSubdomain}.${domain}`,
      headers: {
        'x-lum-tenant-subdomain': safeSubdomain,
        'Cache-Control': 'no-cache',
      },
    }
  );

  const messageValue = context.query[MESSAGE_QUERY_PARAM] ?? null;

  // guard against a redirect path which nextjs converts to an array
  // we don't need to decode it because next does that for us
  const redirectPath =
    typeof context.query.redirect === 'string' ? context.query.redirect : null;

  const userEmail =
    typeof context.query[EMAIL_QUERY_PARAM] === 'string'
      ? context.query[EMAIL_QUERY_PARAM]
      : null;

  return {
    props: {
      redirectPath,
      userEmail,
      branding: tenant_branding,
      // we want to be able to provide good handling for a user mistyping their tenant subdomain; e.g. if
      // they type "luminary" instead of "luminarylabs", we want to be able to show them a helpful error
      invalidTenantSubdomain: tenantDoesNotExist ? safeSubdomain : null,
      tenantURL: context.req.headers.host ?? '',

      statusMessage: getValidatedStatusMessage(messageValue as string | null), // this should not ever be a string[]
    },
  };
}

export default function Login({
  branding,
  redirectPath,
  invalidTenantSubdomain,
  tenantURL,
  statusMessage,
  userEmail,
}: LoginProps) {
  const { display_name: displayName } = branding;

  const theme = getThemeFromTenantBranding({
    id: branding.id,
    displayName: branding.display_name ?? 'Luminary',
    primaryColor: branding.primary_color,
    secondaryColor: branding.secondary_color,
    dataVisualizationPrimaryColor: branding.data_visualization_primary_color,
    dataVisualizationSecondaryColor:
      branding.data_visualization_secondary_color,
    // TODO (T2-747): remove this fallback once optionality is removed from the API
    dataVisualizationTertiaryColor:
      branding.data_visualization_tertiary_color ||
      branding.data_visualization_secondary_color,
    dataVisualizationNegativeColor: branding.data_visualization_negative_color,
    buttonsLinkColor: branding.buttons_link_color,
    lightWideLogoURL: branding.logo_url,
  });

  return (
    <>
      <Head>
        <ViewportMeta />
        <title>Luminary | Login</title>
      </Head>

      <ThemeProvider theme={theme}>
        <FeedbackProvider>
          <CssBaseline>
            <LoginPage
              initialStatusMessage={statusMessage}
              displayName={displayName ?? 'Luminary'}
              invalidTenantSubdomain={invalidTenantSubdomain}
              tenantURL={tenantURL}
              redirectPath={redirectPath}
              initialUserEmail={userEmail}
            />
          </CssBaseline>
        </FeedbackProvider>
      </ThemeProvider>
    </>
  );
}
