import { Box, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import {
  LoginLogomark,
  LogomarkSize,
} from '@/components/brand/Logomark/Logomark';
import { Card } from '@/components/layout/Card';

import { LoginForm } from './components/LoginForm';
import {
  ErrorNotification,
  InvalidTenantNotification,
  LoginAttemptNotification,
  MismatchedEmailNotification,
} from './components/LoginNotifications';
import { LoginCodes, MESSAGE_QUERY_PARAM } from './LoginPage.constants';
import {
  getAuthEmailFromCookie,
  getValidatedStatusMessageClient,
} from './LoginPage.utils';

interface LoginPageProps {
  displayName: string;
  invalidTenantSubdomain: string | null;
  tenantURL: string;
  initialStatusMessage: LoginCodes | null;
  redirectPath: string | null;
  // initialUserEmail is used to prefill the email input
  initialUserEmail: string | null;
}

export function LoginPage({
  displayName,
  invalidTenantSubdomain,
  tenantURL,
  initialStatusMessage,
  redirectPath,
  initialUserEmail,
}: LoginPageProps) {
  const theme = useTheme();
  const email = getAuthEmailFromCookie();
  const currentStatusMessage = getValidatedStatusMessageClient();

  // this isn't beautiful, but i'm learning how to handle the client/server interplay with next.
  // this is basically just mapping the query param into a validated state param so the UI updates
  // to match the URL.
  const [loginCode, setLoginCode] = useState<LoginCodes | null>(
    initialStatusMessage
  );
  useEffect(() => {
    if (!currentStatusMessage) return;
    setLoginCode(currentStatusMessage);
  }, [currentStatusMessage, setLoginCode]);

  // this should strip the message query param from the URL and reload the page
  function handleRetryLogin() {
    const url = new URL(window.location.href);
    url.searchParams.delete(MESSAGE_QUERY_PARAM);
    window.history.replaceState({}, window.document.title, url.toString());
    // inform the UI that the state has changed so the login form is shown again
    setLoginCode(null);
  }

  return (
    <Box
      sx={{
        backgroundColor: '#fafafa',
        width: '100%',
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        variant="outlined"
        sx={{
          px: 3,
          py: 5,
          width: 450,
          boxShadow: theme.palette.shadows.lg,
        }}
      >
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="center">
            <LoginLogomark
              alt={displayName}
              width="70%"
              style={{
                // the goal here is to have the logo have the correct aspect ratio,
                // but not have it overflow horizontally or take up too much
                // vertical space.
                maxWidth: '100%',
                width: 'auto',
                maxHeight: 150,
              }}
              size={LogomarkSize.Wide}
            />
          </Stack>
          {invalidTenantSubdomain ? (
            <InvalidTenantNotification
              tenantURL={tenantURL}
              subdomain={invalidTenantSubdomain}
            />
          ) : (
            <>
              {loginCode === LoginCodes.LINK_SENT && (
                <LoginAttemptNotification
                  onRetryLogin={handleRetryLogin}
                  email={email}
                />
              )}
              {loginCode === LoginCodes.UNKNOWN_ERROR && (
                <ErrorNotification onRetryLogin={handleRetryLogin} />
              )}
              {loginCode === LoginCodes.EMAIL_NOT_FOUND && (
                <MismatchedEmailNotification
                  email={email}
                  onRetryLogin={handleRetryLogin}
                />
              )}
              {!loginCode && (
                <LoginForm
                  initialUserEmail={initialUserEmail}
                  redirectPath={redirectPath}
                />
              )}
            </>
          )}
        </Stack>
      </Card>
    </Box>
  );
}
