// this query param is used as part of the redirect flow to pass state
// information from the backend to the UI. The codes passed to it
// map to the `LoginCodes` enum.
export const MESSAGE_QUERY_PARAM = 'message';

// this query param is used as part of the invite flow to prepopulate
// the invited user's email in the login form
export const EMAIL_QUERY_PARAM = 'email';

// the AUTH_V2_INITIATE_COOKIE is set by the backend when a user attempts
// a login. it contains the email address of the user attempting to login, which
// we don't want to put into a query parameter to avoid leaking it to third party
// systems and log aggregators.
// it is a base64-encoded JSON object with the following shape: `{ "email": string }`
export const AUTH_V2_INITIATE_COOKIE = '_session_initiate';

export enum LoginCodes {
  LINK_SENT = 'LINK_SENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
}
