// i need to call atob on the server as well, and node doesn't have an atob global,
// so we're using a wrapper package
import atob from 'atob';
import Cookies from 'js-cookie';

import {
  AUTH_V2_INITIATE_COOKIE,
  LoginCodes,
  MESSAGE_QUERY_PARAM,
} from './LoginPage.constants';

export function getAuthEmailFromCookie(): string | null {
  // this cookie is a base64-encoded JSON object with the following shape: `{ "email": string }`
  const cookieValue = Cookies.get(AUTH_V2_INITIATE_COOKIE);
  if (!cookieValue) return null;
  try {
    const authCookieJSONString = atob(cookieValue);
    const res = JSON.parse(authCookieJSONString) as
      | { email?: string }
      | undefined;
    return res?.email || null;
  } catch (_err) {
    // it's expected to not get the cookie sometimes
    return null;
  }
}

/**
 * @description This function is used to validate the status message query param, guaranteeing either a valid LoginCode or null
 */
export function getValidatedStatusMessage(
  statusMessage: string | null
): LoginCodes | null {
  if (!statusMessage || !Object.keys(LoginCodes).includes(statusMessage)) {
    return null;
  }

  return statusMessage as LoginCodes;
}

export function getValidatedStatusMessageClient(): LoginCodes | null {
  if (typeof window === 'undefined') {
    return null;
  }

  const rawStatusMessage = new URL(window.location.href).searchParams.get(
    MESSAGE_QUERY_PARAM
  );
  return getValidatedStatusMessage(rawStatusMessage);
}
