import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { LinkButton } from '@/components/form/baseInputs/Link';

export interface LoginAttemptNotificationProps {
  email: string | null;
  onRetryLogin: () => void;
}

export function LoginAttemptNotification({
  email,
  onRetryLogin,
}: LoginAttemptNotificationProps) {
  const [isClientSide, setIsClientSide] = useState(false);
  useEffect(() => setIsClientSide(true), []);
  const copy = useMemo(() => {
    if (email && isClientSide) {
      return (
        <>
          A link to log in has been sent to <b>{email}</b>.
        </>
      );
    }

    // this shouldn't happen, but just in case.
    return <>A link to log in has been sent to your email.</>;
  }, [email, isClientSide]);

  return (
    <Stack spacing={2} textAlign="center">
      {/* this needs suppressHydrationWarning because when we're on the server, the cookie won't be set yet, but it'll be fine on the client */}
      <Typography variant="body2" suppressHydrationWarning>
        {copy}
      </Typography>
      <Typography variant="subtitle2">
        You may close this browser tab after you log in.
      </Typography>
      <Box>
        <LinkButton display="Wrong email?" onClick={onRetryLogin} />
      </Box>
    </Stack>
  );
}

export interface ErrorNotificationProps {
  onRetryLogin: () => void;
}

export function ErrorNotification({ onRetryLogin }: ErrorNotificationProps) {
  return (
    <Stack spacing={2} textAlign="center">
      <Typography variant="body2">
        An unknown error occurred. Please try again.
      </Typography>
      <Box>
        <Button variant="primary" size="lg" onClick={onRetryLogin}>
          Try again
        </Button>
      </Box>
    </Stack>
  );
}

export interface MismatchedEmailNotificationProps {
  onRetryLogin: () => void;
  email: string | null;
}

export function MismatchedEmailNotification({
  onRetryLogin,
  email,
}: MismatchedEmailNotificationProps) {
  const copy = email ? `for ${email}` : 'for the email you provided';
  return (
    <Stack spacing={2} textAlign="center">
      <Typography variant="body2">
        No account was found {copy}. Please try again.
      </Typography>
      <Box>
        <Button variant="primary" size="lg" onClick={onRetryLogin}>
          Try again
        </Button>
      </Box>
    </Stack>
  );
}

interface InvalidTenantNotificationProps {
  subdomain: string;
  tenantURL: string;
}

function createBoldedSubstring(text: string, substring: string): JSX.Element {
  const index = text.indexOf(substring);
  if (index === -1) {
    return <>{text}</>;
  }

  const before = text.slice(0, index);
  const after = text.slice(index + substring.length);

  return (
    <>
      {before}
      <b>{substring}</b>
      {after}
    </>
  );
}

export function InvalidTenantNotification({
  subdomain,
  tenantURL,
}: InvalidTenantNotificationProps) {
  const boldedTenantURL = useMemo(() => {
    return createBoldedSubstring(tenantURL, subdomain);
  }, [tenantURL, subdomain]);
  return (
    <Stack spacing={2} textAlign="center">
      <Typography variant="body2">
        This URL is not valid. Please double-check the subdomain of the URL
        you&apos;ve accessed. ({boldedTenantURL})
      </Typography>
      <Typography variant="subtitle2">
        If you need assistance, feel free to reach out to{' '}
        <a href="mailto:support@withluminary.com">support@withluminary.com</a>.
      </Typography>
    </Stack>
  );
}
