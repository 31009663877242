import { Box, Stack, Typography } from '@mui/material';
// Use the useForm hook from the library instead of our custom one
// here because we don't use any custom context on the login
// page.
// eslint-disable-next-line no-restricted-imports
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { Link } from '@/components/form/baseInputs/Link';
import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { formPost } from '@/utils/formUtils';

interface LoginFormShape {
  email: string;
  rememberExtendedSession: boolean;
}

interface LoginFormProps {
  // redirectPath is the path that the user will be redirected to after they log in.
  redirectPath: string | null;
  // initialUserEmail is used to prefill the email input
  initialUserEmail: string | null;
}

export function LoginForm({ redirectPath, initialUserEmail }: LoginFormProps) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginFormShape>({
    defaultValues: {
      email: initialUserEmail ?? '',
      rememberExtendedSession: false,
    },
  });

  const onSubmit: SubmitHandler<LoginFormShape> = (values) => {
    formPost('/api/auth/v2/initiate', {
      email: values.email,
      remember_extended_session: values.rememberExtendedSession,
      ...(redirectPath ? { redirect: redirectPath } : {}),
    });
    // we want to return an empty promise here so that that the react-hook-form
    // isSubmitting state flips to true. we don't worry about resolving it because
    // the form post will cause a full page reload.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return new Promise(() => {});
  };

  return (
    <FormConfigurationProvider
      value={{ optionalDisplayType: 'optional-label' }}
    >
      <Stack
        noValidate
        textAlign="center"
        spacing={4}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant="body2" component="p">
          Log in to continue to Luminary
        </Typography>
        <Box textAlign="left">
          <FormAwareTextInput<LoginFormShape>
            label="Email address"
            required
            type="email"
            fieldName="email"
            control={control}
            disabled={isSubmitting}
          />
          {/* hide the (optional) param */}
          <FormConfigurationProvider
            value={{ optionalDisplayType: 'required-asterisk' }}
          >
            <Box mt={1}>
              <FormAwareCheckbox<LoginFormShape>
                label="Keep me logged in for 30 days"
                fieldName="rememberExtendedSession"
                control={control}
                required={true}
                disabled={isSubmitting}
              />
            </Box>
          </FormConfigurationProvider>
        </Box>
        <Button
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
          variant="primary"
          size="lg"
          type="submit"
        >
          Continue
        </Button>
        <TermsAcknowledgement />
      </Stack>
    </FormConfigurationProvider>
  );
}

function TermsAcknowledgement() {
  return (
    <Typography variant="subtitle2" component="div">
      By clicking &apos;Continue&apos;, I acknowledge receipt of the <br />
      <Link
        href="https://www.withluminary.com/privacy"
        target="_blank"
        rel="noreferrer"
        display="Luminary Privacy Policy"
      />
      &nbsp;and&nbsp;
      <Link
        rel="noreferrer"
        href="https://www.withluminary.com/terms"
        target="_blank"
        display="Luminary Terms of Service"
      />
      .
    </Typography>
  );
}
